import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
    MeterWrappper, SubCosts,Cost
} from './MeterStyled';
export const MeterContainer = ({annual, fortnight,isLoading, heading1,text1, heading2, text2, runningCost ,carCost, shareOfSavings, perFortnight, slug }) => {
    var digits = annual.toString().split('').map(num=>!isNaN(parseInt(num)) ? parseInt(num) : num);
    var digits2 = fortnight.toString().split('').map(num=>!isNaN(parseInt(num)) ? parseInt(num) : num);
    if (digits.length<5){
        let padZeros = 5-digits.length;
        digits = Array(padZeros).fill(0).concat(digits);
    }
    if (digits2.length<4){
      let padZeros = 4-digits2.length;
      digits2 = Array(padZeros).fill(0).concat(digits2);
  }

    const counterAnim = (qSelector, start, end, duration = 1000) => {
      const target = document.querySelector(qSelector);
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
          const progress = Math.min((timestamp - startTimestamp) / duration, 1);
          target.innerText = Math.floor(progress * (end - start) + start);
          if (progress < 1) {
            window.requestAnimationFrame(step);
          }
      };
      window.requestAnimationFrame(step);
    };
    
    const usePrevious = (value)=> {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }

    const prevDigits = usePrevious(digits) || Array(digits.length).fill(0);
    const prevDigits2 = usePrevious(digits2) || Array(digits2.length).fill(0);
    const prevRCost = usePrevious(runningCost) || 0;
    const prevCCost = usePrevious(carCost) || 0;
    const shareOfSavingsFormatted = isNaN(shareOfSavings) ? '000' : parseInt(shareOfSavings);
    const perFortnightFormatted = isNaN(perFortnight) ? '000' : parseInt(perFortnight);

    useEffect(()=>{
      if(!isLoading){
        digits.forEach((number,i) => {
          if(!isNaN(parseInt(number))){
            counterAnim(`#annual${i}`, parseInt(prevDigits[i]) || 0, number, 500);
          }
        });
        digits2.forEach((number,i) => {
          if(!isNaN(parseInt(number))){
            counterAnim(`#fortnight${i}`, parseInt(prevDigits2[i]) || 0, number, 500);
          }
        });
        if(runningCost)
          counterAnim(`#r_cost`, prevRCost, runningCost, 500);
        if(carCost)
          counterAnim(`#c_cost`, prevCCost, carCost, 500);
      }
// eslint-disable-next-line
    },[digits,isLoading, digits2])
    const renderAnnual = ()=>{
      return digits.map((number,i) => {
        return <span id={`annual${i}`} key={i}>{number}</span>
      });
    }
    const renderFortnight = ()=>{
      return digits2.map((number,i) => {
        return <span id={`fortnight${i}`} key={i}>{number}</span>
      });
    }
    return (
      <MeterWrappper>
        <p className='heading text'>{heading1}</p>
        <div className="amount">
          <h4>$</h4>
          {renderAnnual()}
        </div>
        <p className='text'>{text1}</p>
        <hr/>
        <p className='heading text'>{heading2}</p>
        <div className="amount">
          <h4>$</h4>
          {renderFortnight()}
        </div>
        <p className='text'>{text2}</p>
        <SubCosts>
          <Cost>
            <p>Fortnightly running costs</p>
            <h3>$<span id="r_cost">{runningCost || '000'}</span></h3>
          </Cost>
          <Cost>
            <p>Fortnightly cost of car</p>
            <h3>$<span id="c_cost">{carCost || '000'}</span></h3>
          </Cost>
        </SubCosts>
        {slug === 'nsw-health' && (
            <SubCosts>
                <Cost>
                    <p>Share of savings</p>
                    <h3>
                        $
                        <span id="sos_cost">
                            {shareOfSavingsFormatted || '000'}
                        </span>
                    </h3>
                </Cost>
                <Cost>
                    <p>Per fortnight </p>
                    <h3>
                        $
                        <span id="pf_cost">
                            {perFortnightFormatted || '000'}
                        </span>
                    </h3>
                </Cost>
            </SubCosts>
        )}
      </MeterWrappper>
    
    );
};

const mapStateToProps = (state) => {
  return {
    isLoading : state.vehicle.isLoading,
  };
 };
  
 export const Meter = connect(mapStateToProps)(MeterContainer);
