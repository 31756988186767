import React from 'react';
import {
  NavbarWrapper
} from './NavbarMenuStyled';
import {
  Navbar,
  Nav,

} from 'react-bootstrap';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

export const NavbarMenu = ({menuCollapse,setMenuCollapse}) => {
        const menuIconClick = () => {
          menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
        };

    return (
      <div>
      <NavbarWrapper>
        <Navbar expand="*">
            <Navbar.Brand href="/" className="logo"/>
              <div className="ml-auto d-inline-flex">
                <Nav.Link href="tel:1300456692">1300 456 692</Nav.Link>
                <Nav.Link href="#home">|</Nav.Link>
                <Nav.Link href="https://www.kooya.com.au/#contact-form-section">Request a callback</Nav.Link>
                <Nav.Link href="https://members.kooya.com.au/login">Login</Nav.Link>
              </div>
              <div><img className="phone" alt="phone" src="/Images/phone.png"/></div>
              <div className="closemenu" onClick={menuIconClick}>
                            {/* changing menu collapse icon on click */}
                          {menuCollapse ? (
                             <button className="navbar-toggler nav-toggle-btn" type="button" aria-expanded="false"
                             aria-label="Toggle navigation">

                             <div className="animated-icon"><span></span><span></span><span></span><span></span></div>
                           </button>

                          ) : (
                            <button className="navbar-toggler nav-toggle-btn" type="button" aria-expanded="false"
                            aria-label="Toggle navigation">

                            <div className="animated-icon"><span className="top-bar"></span><span className="middle-bar"></span><span className="middle-bar"></span><span className="bottom-bar"></span></div>
                          </button>
                          )}
              </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#home">1300 456 692</Nav.Link>
                <Nav.Link href="#link">Request a callback</Nav.Link>
                <Nav.Link href="#link">Login</Nav.Link>
              </Nav>
            </Navbar.Collapse>
      </Navbar>
              <div id="header" >
                    <ProSidebar collapsed={menuCollapse}>
                      <SidebarContent>
                        <Menu iconShape="square">
                          <MenuItem>
                           <a href="https://www.kooya.com.au/"> <h2 className="Sidebar-Heading">Home</h2></a>
                           <a href="https://www.kooya.com.au/#ev-module"> <h2 className="Sidebar-Heading">Electric Vehicle Novated Leasing</h2></a>
                          </MenuItem>
                          <hr/>
                        </Menu>
                      </SidebarContent>
                    </ProSidebar>
             </div>
    </NavbarWrapper>
    </div>

    );
};
