
export const stateOptions = [
    { value: 'VIC', label: 'VIC' },
    { value: 'WA', label: 'WA' },
    { value: 'NSW', label: 'NSW' },
    { value: 'QLD', label: 'QLD' },
    { value: 'ACT', label: 'ACT' },
    { value: 'SA', label: 'SA' },
    { value: 'TAS', label: 'TAS' },
    { value: 'NT', label: 'NT' },
  ];

export const timelineOptions = [
    { value: 'asap', label: 'ASAP' },
    { value: 'three_months', label: '3 Months' },
    { value: 'six_months', label: '6 Months' },
  ]

export const preSelectVariants = [
  { value: 168843, label: 'Small' },
  { value: 171627, label: 'Medium' },
  { value: 172131, label: 'Large' },
];

export const evOptions = [
  { value: '182590', label: 'BMW iX1' },
  { value: '183691', label: 'BYD Atto 3' },
  { value: '183693', label: 'BYD Atto 3 Extended Range' },
  { value: '182527', label: 'Cupra Born' },
  { value: '182530', label: 'Cupra Formentor VZe PHEV' },
  { value: '183384', label: 'Cupra Leon ZVe PHEV' },
  { value: '181787', label: 'Ford Escape ST-Line PHEV' },
  { value: '184557', label: 'GWM ORA Standard Range' },
  { value: '184558', label: 'GWM ORA Extended Range' },
  { value: '183272', label: 'Hyundai Ioniq 5 Dynamic' },
  { value: '183273', label: 'Hyundai Ioniq 5 Techniq' },
  { value: '183281', label: 'Hyundai Ioniq 5 Epiq' },
  { value: '184357', label: 'Hyundai Ioniq 6 Dynamic' },
  { value: '184363', label: 'Hyundai Ioniq 6 Techniq' },
  { value: '184363', label: 'Hyundai Ioniq 6 Epiq' },
  { value: '183819', label: 'Hyundai Kona Electric Elite' },
  { value: '183813', label: 'Hyundai Kona Electric Elite (Extended)' },
  { value: '183821', label: 'Hyundai Kona Electric Highlander' },
  { value: '183817', label: 'Hyundai Kona Electric Highlander (Extended)' },
  { value: '181917', label: 'Kia EV6' },
  { value: '184088', label: 'Kia EV6 Air' },
  { value: '184090', label: 'Kia EV6 GT-Line' },
  { value: '184092', label: 'Kia EV6 GT-Line AWD' },
  { value: '183264', label: 'Kia Sorento PHEV GT-Line (7 seats)' },
  { value: '182618', label: 'Kia Niro S Electric' },
  { value: '182621', label: 'Kia Niro Sport' },
  { value: '182615', label: 'Kia Niro Electric GT Line' },
  { value: '177748', label: 'Lexus UX300e Luxury' },
  { value: '177750', label: 'Lexus UX300e Sports Luxury' },
  { value: '183881', label: 'Mazda CX60 Evolve' },
  { value: '183883', label: 'Mazda CX60 Evolve PHEV' },
  { value: '183885', label: 'Mazda CX60 Evolve VISION' },
  { value: '183884', label: 'Mazda CX60 Evolve VISION PHEV' },
  { value: '183890', label: 'Mazda CX60 GT' },
  { value: '183888', label: 'Mazda CX60 GT D50E GT Hybrid' },
  { value: '183889', label: 'Mazda CX60 GT D50E GT Vision' },
  { value: '183886', label: 'Mazda CX60 GT G40E GT Hybrid' },
  { value: '183887', label: 'Mazda CX60 GT G40E GT Vision Hybrid' },
  { value: '183895', label: 'Mazda CX60 Azami' },
  { value: '184819', label: 'Mazda MX-30 Electric Astina' },
  { value: '181337', label: 'Mazda MX-30 Electric E35 Astina' },
  { value: '182963', label: 'MG ZS EV Exite' },
  { value: '182964', label: 'MG ZS EV Essence' },
  { value: '181948', label: 'MG HS PHEV Plus Excite' },
  { value: '181906', label: 'MG HS PHEV Plus Essence' },
  { value: '181343', label: 'Mini Countryman Plugin Hybrid Classic Edition' },
  { value: '181344', label: 'Mini Countryman Plugin Hybrid Yours Edition' },
  { value: '181474', label: 'MINI Electric 3D' },
  { value: '181445', label: 'MINI Electric 5D' },
  { value: '181637', label: 'Mitsubishi Eclipse Cross Hybrid EV Aspire' },
  { value: '181638', label: 'Mitsubishi Eclipse Cross Hybrid EV Exceed' },
  { value: '183765', label: 'Mitsubishi Outlander PHEV EV ES (5 seats)' },
  { value: '183767', label: 'Mitsubishi Outlander PHEV EV Aspire (5 seats)' },
  { value: '183769', label: 'Mitsubishi Outlander Exceed PHEV (7 seats)' },
  { value: '183771', label: 'Mitsubishi Outlander Exceed Tourer PHEV AWD (7 seats)' },
  { value: '183450', label: 'Mercedes-Benz EQA250' },
  { value: '182158', label: 'Nissan Leaf' },
  { value: '182159', label: 'Nissan Leaf e+' },
  { value: '184808', label: 'Peugeot 308 GT PHEV' },
  { value: '184854', label: 'Peugeot 3008 GT Sport Plug-In Hybrid' },
  { value: '181792', label: 'Peugeot 508 Fastback Plug-in Hybrid' },
  { value: '181887', label: 'Polestar 2' },
  { value: '181886', label: 'Polestar 2 Long Range Single Motor' },
  { value: '181885', label: 'Polestar 2 Long Range Dual Motor' },
  { value: '184082', label: 'Tesla Model 3 Rear-Wheel-Drive' },
  { value: '184083', label: 'Tesla Model 3 All-Wheel-Drive (Long Range)' },
  { value: '184084', label: 'Tesla Model 3 Performance' },
  { value: '184843', label: 'Tesla Model Y Long-Range' },
  { value: '184085', label: 'Tesla Model Y Rear-Wheel-Drive' },
  { value: '184170', label: 'Volvo C40 Recharge Pure Electric'},
  { value: '184171', label: 'Volvo C40 Ultimate Twin Pure Electric'},
  { value: '182428', label: 'Volvo XC40 Recharge Electric Single Motor'},
  { value: '182429', label: 'Volvo XC40 Rechage Electric Twin Motor' },
];

//EV Category 
export const evCategory = [
  { value: '1', label: 'Hatchbacks' },
  { value: '2', label: 'Sedans' },
  { value: '3', label: `Compact SUV's` },
  { value: '4', label: `Medium & Large SUV's` }
];

//Plug-In Hybrid Category 
export const pluginHVCategory = [
  { value: '1', label: `Compact SUV's & Hatchbacks` },
  { value: '2', label: `Medium Sized SUV's` },
  { value: '3', label:'Large SUV & 7-Seat vehicles' }
];

// EV options
export const HatchbacksOptions = [
  { value: '185267', label: 'BYD Dolphin Dynamic' },
  { value: '185268', label: 'BYD Dolphin Premium' },
  { value: '185233', label: 'Fiat 500e La Prima' },
  { value: '184557', label: 'GWM ORA Standard Range' },
  { value: '184558', label: 'GWM ORA Extended Range' },
  { value: '185091', label: 'GWM ORA Ultra Extended Range'},
  { value: '185092', label: 'GWM ORA GT' },
  { value: '185270', label: 'MG MG4 Excite 51' },
  { value: '185271', label: 'MG MG4 Excite 64' },
  { value: '185272', label: 'MG MG4 Essence 64' },
  { value: '185273', label: 'MG MG4 Long Range 77' },
  { value: '182158', label: 'Nissan Leaf' },
  { value: '182159', label: 'Nissan Leaf e+' },	
];
export const SedansOptions = [
  { value: '184357', label: 'Hyundai Ioniq 6 Dynamic' },
  { value: '184363', label: 'Hyundai Ioniq 6 Techniq' },
  { value: '184365', label: 'Hyundai Ioniq 6 Epiq' },
  { value: '184941', label: 'Polestar 2 Standard Range Single Motor'},
  { value: '184939', label: 'Polestar 2 Long Range Single Motor'},
  { value: '184937', label: 'Polestar 2 Long Range Dual Motor'},
  { value: '184082', label: 'Tesla Model 3 Rear-Wheel-Drive'},
  { value: '185588', label: 'Tesla Model 3 Long-Range (all-wheel-drive)'},
];

export const CompactSUVOptions = [
  { value: '183691', label: 'BYD Atto 3 Standard Range' },
  { value: '183693', label: 'BYD Atto 3 Extended Range' },
  { value: '184188', label: 'Cupra Born' },
  { value: '185555', label: 'Ford Mustang Mach-E Select' },
  { value: '185591', label: 'Hyundai Ioniq 5 Dynamic' },
  { value: '183273', label: 'Hyundai Ioniq 5 Techniq' },
  { value: '185596', label: 'Hyundai Ioniq 5 Epiq' },
  { value: '184088', label: 'Kia EV6 Air' },
  { value: '184090', label: 'Kia EV6 GT-Line' },
  { value: '184092', label: 'Kia EV6 GT-Line AWD' },
  { value: '184964', label: 'Kia Niro S' },
  { value: '184967', label: 'Kia Niro GT Line' },
  { value: '184819', label: 'Mazda MX-30 Electric E35 Astina' },
  { value: '184604', label: 'MG ZS EV Exite' },
  { value: '184605', label: 'MG ZS EV Essence' },
  { value: '185347', label: 'Renault Megane E-Tech' },
  { value: '185485', label: 'Volvo EX30 Ultra Twin-Motor Pure Electric' },
];
export const MdLgSUVOptions = [
  { value: '184913', label: 'Lexus UX300e Luxury' },
  { value: '184914', label: 'Lexus UX300e Sports Luxury' },
  { value: '185048', label: 'Mercedes-Benz EQA250' },
  { value: '184085', label: 'Tesla Model Y Rear-Wheel-Drive' },
  { value: '184843', label: 'Tesla Model Y Long-Range' },
  { value: '185289', label: 'Volvo C40 Recharge Pure Electric' },	
];

// end of EV Options

//Plug-In Hybrid Vehicles Options
export const SUVHatchbacksOptions = [
  { value: '185120', label: 'Cupra Leon VZe PHEV' },
  { value: '184208', label: 'Ford Escape ST-Line PHEV' },
  { value: '184598', label: 'MG HS PHEV Plus Excite' },
  { value: '184597', label: 'MG HS PHEV Plus Essence' },
  { value: '184808', label: 'Peugeot 308 GT PHEV' },
];

export const SUVMediumOptions = [
  { value: '185125', label: 'Cupra Formentor VZe PHEV' },
  { value: '183884', label: 'Mazda CX60 P50e Evolve' },
  { value: '183890', label: 'Mazda CX60 P50e GT' },
  { value: '183898', label: 'Mazda CX60 P50e Azami' },
  { value: '184493', label: 'Mitsubishi Eclipse Cross PHEV ES' },
  { value: '184495', label: 'Mitsubishi Eclipse Cross PHEV Aspire' },
  { value: '184497', label: 'Mitsubishi Eclipse Cross PHEV Exceed' },
];

export const SUVLarge = [
  { value: '183264', label: 'Kia Sorento PHEV GT-Line (7 seats)' },
  { value: '185656', label: 'Mitsubishi Outlander PHEV EV ES (5 seats)' },
  { value: '183767', label: 'Mitsubishi Outlander PHEV EV Aspire (5 seats)' },
  { value: '185659', label: 'Mitsubishi Outlander Exceed PHEV (7 seats)' },
  { value: '185660', label: 'Mitsubishi Outlander Exceed Tourer PHEV AWD (7 seats)' },	  
];
// end of Plug-In Hybrid Vehicles Options


export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


