import React from "react";
import {
Box,
FooterLink,
Heading,
Hr,
} from "./FooterStyled";
import {Container,
	Row,
	Col,} from 'react-bootstrap'
export const Footer = ({menuCollapse}) => {

return (
	<Box id="footer" menuCollapse={menuCollapse}>
	<Container>
		<Row className="display-hide">
			<Col  sm={8} className="text-left">
				<Row>

						<Col  sm={3}>
						    <ul className="list-unstyled">
								<li><Heading href="https://www.kooya.com.au/#about">About Us</Heading></li>
								<li><Heading href="https://members.kooya.com.au/login">Login</Heading></li>
							</ul>
						</Col>

					<Col  sm={3}>
						<ul className="list-unstyled">

						</ul>
					</Col>
					<Col  sm={3}>
						<ul className="list-unstyled">

						</ul>
					</Col>
					<Col  sm={3}>

					</Col>

				</Row>
			</Col>
			<Col className="text-right">
				<ul className="list-unstyled">
					<li><Heading as="div">Phone: <FooterLink href="#">1300 456 692</FooterLink></Heading></li>
					<li><Heading as="div">Fax: <FooterLink href="#">1300 655 568</FooterLink></Heading></li>
					<br />
					<li><Heading as="div">Email: <FooterLink href="mailto:ask@kooya.com.au">ask@kooya.com.au</FooterLink></Heading></li>
				</ul>
			</Col>
		</Row>
		<Hr/>
		<Row>
			<Col  sm={12} md={12}>
				<ul className="list-unstyled list-horizental">
					<li className="list-right"><Heading as="div"><FooterLink href="https://www.kooya.com.au/privacy.html" target="_blank">Privacy Policy</FooterLink></Heading></li>
					<li className="up-style list-right"><Heading as="div"><FooterLink href="https://www.juicebox.com.au">Site by JuiceBox</FooterLink></Heading></li>
				</ul>
			</Col>
		</Row>
	</Container>
	</Box>
);
};

