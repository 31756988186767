import { navigate } from "@reach/router";
import React, { useState } from "react";
import { useEffect } from "react";
import { Image } from "react-bootstrap";
import { PrimaryButton } from "../PrimaryButton";
import Accordion from "../Accordion/Accordion";
import { faqs } from "./faqs.js";
import {
  FAQSection,
  ThankYouPageWrapper,
  ThankYouSection,
} from "./styled";

export const ThankYou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [openedIndex, setOpenedIndex] = useState(false);

  const renderFAQS = () => {
    return faqs.map((faq, i) => (
      <Accordion
        key={i}
        head={faq.title}
        body={faq.body}
        opened={openedIndex === i}
        handleOnClick={() => {
          if (openedIndex === i) {
            setOpenedIndex(false);
          } else {
            setOpenedIndex(i);
          }
        }}
      />
    ));
  };

  return (
    <ThankYouPageWrapper>
      <Image className="small-cloud" alt="icons" src="/Images/Vector3.png" />
      <Image className="big-cloud" alt="icon" src="/Images/Vector.png" />
      <Image className="half-cloud" alt="icons" src="/Images/Vector2.png" />

      <ThankYouSection className="container">
        <h1>Thank You!</h1>
        <p>
          Thank you for enquiring about a novated car lease. 
          We will be in touch with you very soon with your request.
        </p>
        <PrimaryButton
          type="button"
          btnText="Return to Home Page"
          handleOnclick={() => navigate("/")}
        />
      </ThankYouSection>
      <FAQSection className="container">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-container">{renderFAQS()}</div>
      </FAQSection>
    </ThankYouPageWrapper>
  );
};
