import axios from 'axios';
import * as constants from './actionTypes';

const Base_Url = process.env.REACT_APP_API_URL;

export const resetMakes = () => ({ type: constants.GET_MAKES, payload: [] });
export const resetModels = () => ({ type: constants.GET_MODELS, payload: [] });
export const resetModelYears = () => ({ type: constants.GET_MODEL_YEARS, payload: [] });
export const resetBodyType = () => ({ type: constants.GET_BODY_TYPE, payload: [] });
export const resetVariants = () => ({ type: constants.GET_VARIANTS, payload: [] });
export const resetVariant = () => ({ type: constants.GET_VARIANT, payload: [] });
export const resetQuote = () => ({ type: constants.GET_QUOTE, payload: null })

export const getMakes = () => {
    return async (dispatch) => {
        (async () => {
            await dispatch(resetMakes());
            await dispatch(resetModels());
            await dispatch(resetModelYears());
            await dispatch(resetBodyType());
            await dispatch(resetVariants());
            await dispatch(resetVariant())
            await dispatch(resetQuote());

            var vehicle_makes=[];
            const makes = await axios.get(Base_Url+'endpoint=getMakes').then(res => {
                const response = res.data.makes || {};
                return Object.values(response);
              })
              vehicle_makes = makes.map((elements) => {
                return { value: elements.make_id, label: elements.name }
                    }
                )
            await dispatch({ type: constants.GET_MAKES, payload: vehicle_makes });
        })();
    };
};


export const getModels = (make_id) => {
    return async (dispatch) => {
        (async () => {
            await dispatch(resetModels());
            await dispatch(resetModelYears());
            await dispatch(resetBodyType());
            await dispatch(resetVariants());
            await dispatch(resetVariant())
            await dispatch(resetQuote());
            await dispatch({ type:constants.IS_LOADING, payload:true });

            var vehicle_models=[];
            const models = await axios.get(Base_Url+'endpoint=getModels&make_id='+make_id).then(res => {
                const response = res.data.models;
                return Object.values(response);
              })
                vehicle_models = models.map((elements) => {
                return { value: elements.model_id, label: elements.name }
                    }
                )
                await dispatch({ type: constants.GET_MODELS, payload: vehicle_models });
                await dispatch({ type:constants.IS_LOADING, payload:false });
            })();
        };

};

export const getModelYears = (model_id) => {
    return async (dispatch) => {
        (async () => {
            await dispatch(resetModelYears());
            await dispatch(resetBodyType());
            await dispatch(resetVariants());
            await dispatch(resetVariant())
            await dispatch(resetQuote());
            await dispatch({ type:constants.IS_LOADING, payload:true });

            var vehicle_model_years=[];
            const years = await axios.get(Base_Url+'endpoint=getModelYears&model_id='+model_id).then(res => {
                const response = res.data.model_years;
                    return Object.values(response);
                })
                vehicle_model_years = years.map((elements) => {
                    return { value: elements.model_year, label: elements.model_year}
                });
                vehicle_model_years.sort((a,b)=> (a.value > b.value) ? -1 : ((b.value > a.value) ? 1 : 0));
                await dispatch({ type: constants.GET_MODEL_YEARS, payload: vehicle_model_years });
                await dispatch({ type:constants.IS_LOADING, payload:false });
            })();
        };

};

export const getBodyType = (model_year,model_id) => {
    return async (dispatch) => {
        (async () => {
            await dispatch(resetBodyType());
            await dispatch(resetVariants());
            await dispatch(resetVariant())
            await dispatch(resetQuote());
            await dispatch({ type:constants.IS_LOADING, payload:true });

            var vehicle_body_type=[];
            const body_type = await axios.get(Base_Url+'endpoint=getBodyTypes&model_id='+model_id+'&model_year='+model_year).then(res => {

                const response = res.data.body_types;
                return Object.values(response);
              })
              vehicle_body_type = body_type.map((elements) => {
                return { value: elements.body_type_id, label: elements.body_type}
                    }
                )
                await dispatch({ type: constants.GET_BODY_TYPE, payload: vehicle_body_type });
                await dispatch({ type:constants.IS_LOADING, payload:false });
            })();
        };

};

export const getVariants = (body_type_id,model_year,model_id) => {
    return async (dispatch) => {
        (async () => {
            await dispatch(resetVariants());
            await dispatch(resetVariant())
            await dispatch(resetQuote());
            await dispatch({ type:constants.IS_LOADING, payload:true });

            var vehicle_variants=[];
            const variants = await axios.get(Base_Url+'endpoint=getVariants&model_id='+model_id+'&model_year='+model_year+'&body_type_id='+body_type_id).then(res => {
                const response = res.data.variants;
                return response ? Object.values(response) : [];
              })
              vehicle_variants = variants.map((elements) => {
                return { value: elements.variant_id, label: elements.description}
                    }
                )
                await dispatch({ type: constants.GET_VARIANTS, payload: vehicle_variants });
                await dispatch({ type:constants.IS_LOADING, payload:false });
            })();
        };

};

export const getVariant = (variant_id,values=null, approximate_price = null, client = '', isEv = false) => {
    return async (dispatch) => {
        (async () => {
            await dispatch(resetVariant())
            await dispatch(resetQuote());
            await dispatch({ type:constants.IS_LOADING, payload:true });

            const variant = await axios.get(Base_Url+'endpoint=getVariant&variant_id='+variant_id).then(res => {
                return res.data.variant;
            });
            const fuel_flag = (parseFloat(variant.engine_litres)===0 && parseFloat(variant.fuel_metro)===0) ? 'no' : 'yes';
            if(values){
                await dispatch(getQuote(values.leaseTerm,variant_id,
                    approximate_price ? approximate_price : variant.list_price_gross,values.travelDistance,values.salary,values.state,fuel_flag, client, isEv));
            }
            await dispatch({ type: constants.GET_VARIANT, payload: variant });
            await dispatch({ type:constants.IS_LOADING, payload:false });
        })();
    };
};

export const getQuote = (lease_period,variant_id,approximate_price,annual_kilometres,annual_salary,state,fuel_flag='yes',client='',isEv) => {
    return async (dispatch) => {
        await dispatch(resetQuote());
        await dispatch({type:constants.IS_LOADING,payload:true});
        (async () => {
            const reg_ctp_flag = lease_period < 24 ? 'no' : 'yes';
            const quote = await axios.get(`${Base_Url}contract=MNL&client=${client}&endpoint=getQuote&variant_id=${variant_id}&approximate_price=${approximate_price}&state_registered=${state}&annual_kilometres=${annual_kilometres}&lease_period=${
                lease_period * 12
            }&annual_salary=${annual_salary}&maintenance_service_flag=yes&insurance_flag=yes&reg_ctp_flag=${reg_ctp_flag}&tyres_flag=no&fuel_flag=${
                isEv ? 'no' : fuel_flag
            }&dealer_delivery_net=0&optional_equipment_deselect_flag=yes&quote_id=${
                isEv ? '156845' : ''
            }`).then(res => {
                return res.data.quote;
              })
                await dispatch({type:constants.IS_LOADING, payload:false});
                await dispatch({ type: constants.GET_QUOTE, payload: quote });
            })();
        };

};


export const sendQuoteEmail = (data,make,variant,vehicleModel,bodyType,salary,state,travelDistance,year,leaseTerm,referrer,car_photo,fortnightly_cost,annual_savings,fortnight_running_cost,fortnight_car_cost,params,quote_type,car_type,isEv) => {
    (async () => {
        axios.post(Base_Url+'handler=form',
        {
            data,
            make,
            variant,
            vehicleModel,
            bodyType,
            salary,
            state,
            travelDistance,
            year,
            leaseTerm,
            referrer,
            car_photo,
            fortnightly_cost,
            annual_savings,
            fortnight_running_cost,
            fortnight_car_cost,
            params,
            quote_type,
            car_type,
            isEv,
            },{ headers: { "Content-Type": "multipart/form-data" }})
            .then(res => {
                return true;
            })
            .catch(err=>{
                return false;
            })
        })();
}
