import styled from 'styled-components';
import {theme} from '../../theme';
export const MeterWrappper =  styled.div`


        background: ${theme.colors.kooyablue};
        width: 345px;
        box-shadow: 0px 4px 30px rgba(40, 39, 129, 0.15);
        border-radius: 15px;
        padding:32px 16px;
        .heading{
            font-size:${theme.fontSizes.lg} !important;
            line-height:19.2px;
        }
        .text {
            color:${theme.colors.white};
            font-size: ${theme.fontSizes.md};
            font-family:${theme.fonts.Replica_bold};
            margin: 0px;
            letter-spacing:0.1px;
            line-height:16.8px;
        }
        h4 {
            color:${theme.colors.white};
            font-size: 23px;
            margin: 5px;
        }
        span {
            color:${theme.colors.kooyablue};
            font-family:${theme.fonts.Replica_bold};
            background: ${theme.colors.white};
            padding: 0px 7px;
            font-size:39px;
            margin: 0px 2.539px;
            min-width:35px;
            border-radius:2px;
        }
        .amount{
            display: flex;
            align-items: center;
            margin: 12px 17px;
            justify-content:center;
        }
        hr{
            border-color:white;
        }
        ${theme.mediaQueriesMaxWidth.md}{
            width: 270px;
            p{
                margin-left:10px;

            }
            span {
                padding: 0px 7px;
            }
        }
        ${theme.mediaQueriesMaxWidth.sm}{
            width:auto;
        }
   
`;

export const SubCosts = styled.div`
    display:flex;
    justify-content:space-between;
    color:${theme.colors.white};
    margin-top:${theme.space.sm};
`

export const Cost = styled.div`
    font-size:${theme.fontSizes.xs};
    font-family:${theme.fonts.Replica_bold};
    flex: 1;
    text-align: center;
    p{
        margin-bottom:4px;
    }
    h3{
        font-size:2.33rem;
        span{
            padding:0px;
            margin:0px;
            background:transparent;
            color:${theme.colors.white};
        }
    }

`