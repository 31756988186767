import styled from "styled-components";
import { theme } from "../../theme";
export const LeaseCalculatorWrappper = styled.div`

     .Lease-section{
        position: relative;
        padding: 178px 132px 0px 132px;
        .counter{
           position:sticky;
           top: 180px;
           margin-left: 100px;
           ${theme.mediaQueriesMaxWidth.xl}{
               margin-left:10px;
            }
           ${theme.mediaQueriesMaxWidth.sm}{
               margin: auto;
            }

         }
         .contact-text{
            margin-top: 24px;
            color: #1c242f;
         }
         .contact-text a{
            color: #1c242f;
            text-decoration: underline;
         }
        .sticky {
            position: absolute;
            bottom: -35px;
        }
        .big-cloud{
           position:fixed;
           top:120px;
           left:60%;
        }
        .dollar-icon1{
           position:fixed;
           top:480px;
           right:40px;
        }
        .half-cloud{
           position:fixed;
           top:410px;
           right:0;
        }
        .dollar-icon2{
           position:fixed;
           top:670px;
           right:180px;
        }
        .small-cloud{
           position:fixed;
           top:740px;
           left: 60%;
        }
     }
      .pb{
         padding-bottom: 178px
      }
     .car-icon{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
     }
     .Include {
        margin-top:32px;
        margin-left:0px;
     }
     .lease-heading{
        color:${theme.colors.kooyablue};
        font-size: 60px;
        font-family:${theme.fonts.Replica_bold};

     }
     .Lease-paragraph{
        color:${theme.colors.red};
        font-size: 16px;
        font-family:${theme.fonts.Replica_bold};
     }
     .looking-for{
      margin-top:39px;
      color: ${theme.colors.kooyablue};
      font-size: 23px;
      font-Weight:400;
     }
     .cant-find {
      margin-top: 20px;
      font-size: 14px;
      color: ${theme.colors.kooyablue};
      a {
         color: ${theme.colors.kooyablue};
      }
      a:hover {
         cursor: pointer;
         text-decoration: underline;
      }
     }
     .p-color{
         font-size: 14px !important;
         max-width:500px;
      }
     .Vehicle-size{
        background-color:${theme.colors.lightgrey};
        padding: 48px 32px;
        margin-top: 24px;
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            height:auto;
            width:auto;
            max-width:50%;
            vertical-align: middle;
        }
        p{
            color:${theme.colors.kooyablue};
            font-size: 23px;
            font-family:${theme.fonts.Replica_Regular};

        }
        span{
           color: ${theme.colors.red};
           margin-left:5px;
        }
        .drop-list{
            font-size:16px;
            font-family:${theme.fonts.Replica_bold};
            cursor: pointer;


        }
     }
     .set-items{
      padding-bottom:30px;
     }
     h2 {
      color:${theme.colors.kooyablue};
      font-size: 26px;
      font-family:${theme.fonts.Replica_Regular};
      text-align: left;
      margin-left:15px;

     }

     .Lease-button{
         margin-top: 26px;
        max-width: 345px;
        ${theme.mediaQueriesMaxWidth.sm}{
            width:100%;
            margin-top:32px;
            max-width:initial;
            button{
               width:100%;
            }
        }
        p{
         max-width: 298px;
         margin-right: auto;
         margin-left: auto;
         margin-top: 30px;
         font-size: 14px;

         font-family:${theme.fonts.Replica_Regular};
         color:${theme.colors.kooyablue};
        }
     }
     ${theme.mediaQueriesMaxWidth.ipadpro} {
      .Lease-section{
         padding: 100px 50px 0px 50px;
      }
      .lease-heading{
         font-size: 50px;
         }
      .Lease-images{
          .icon3-img{
            right: -100px;
        }
         .icon5-img  {
            right: 80px;
            top: 525px;
         }
         .icon2-img {
            left: -50px;
         }
          }

      }
     }
     ${theme.mediaQueriesMaxWidth.md}{
      .Lease-section{
         padding: 50px 50px 0px 50px;
      }
      .pb{
         padding-bottom: 59px
      }
     .lease-heading{
      font-size: 35px;
      }

      .Vehicle-size {
         padding: 20px 20px;
         }
         .Lease-images{

            img{ display:none;}
         }
     }

     }
     ${theme.mediaQueries.md}{
      .set-items{
         display:none;
        }
     }
     ${theme.mediaQueriesMaxWidth.sm}{
      .looking-for{
         margin-top:30px;
      }
      .Include {
         text-align:center;
      }
      .lease-heading{
         color:${theme.colors.kooyablue};
         font-size: 33px;
      }
      .Lease-section{
         padding: 130px 15px 59px 15px;
      }

      .Vehicle-size {
         padding: 15px 15px;
         img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            height:auto;
            width:300px;
            vertical-align: middle;
        }
         p{
               color:${theme.colors.kooyablue};
               font-size: 15px;
               font-weight:normal;
            }
         }
         .Lease-images{
            position:relative;
            img{ display:none;}
         }
     }
     ${theme.mediaQueries.sm}{
      .set-items{
         display:none;
        }

     }

     ${theme.mediaQueriesMaxWidth.sm}{
      .mbl-div{
         padding-bottom:32px;
      }
     }
     .only-mobile{
        display:inherit;
         ${theme.mediaQueries.onlyDesktop}{
            display:none;
         }
     }

     .text-kooyablue{
      color:${theme.colors.kooyablue};
     }


`;

export const LeasCardsWrapper = styled.div`
  max-width: 750px;
  margin-left: auto;
`;
export const LeasPackageWrapper = styled.div`
  max-width: 345px;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 32px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 4px 30px rgba(40, 39, 129, 0.15);
  padding: 32px 16px;
  .Include {
    margin-top: 0px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
  }
  ${theme.mediaQueriesMaxWidth.md} {
    width: 270px;
  }
  ${theme.mediaQueriesMaxWidth.sm} {
    display: none;
  }
`;
export const BreakDownTable = styled.div`
  max-width: 750px;
  margin-left: auto;
  ${theme.mediaQueriesMaxWidth.sm} {
    display: none;
  }
`;
export const CardFlex = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  ${theme.mediaQueriesMaxWidth.sm} {
    display: none;
  }
`;

export const CalculatorDiv = styled.div`
  max-width: 750px;
  margin-left: auto;
  padding-bottom: 32px;
`;

export const Tabs = styled.div`
  max-width: 456px;
  display: flex;
  justify-content: space-between;
`;
export const Tab = styled.p`
  color: #6b7788 !important;
  line-height: 32.2px;
  cursor: pointer !important;
  ${(props) =>
    props.isActive &&
    `
      color:${theme.colors.kooyablue} !important;
      text-decoration: underline;
   `}
  ${(props) =>
    props.preActive &&
    `
      color:${theme.colors.kooyablue} !important;
      text-decoration: none;
      cursor:inherit !important;
   `}
`;

export const TabSection = styled.div``;
